@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-Bold.eot");
  src: url("./assets/fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Gilroy-Bold.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-Bold.woff") format("woff"),
    url("./assets/fonts/Gilroy-Bold.ttf") format("truetype"),
    url("./assets/fonts/Gilroy-Bold.svg#Gilroy-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-SemiBold.eot");
  src: url("./assets/fonts/Gilroy-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Gilroy-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Gilroy-SemiBold.woff") format("woff"),
    url("./assets/fonts/Gilroy-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/Gilroy-SemiBold.svg#Gilroy-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Medium.eot");
  src: url("./assets/fonts/SFProDisplay-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SFProDisplay-Medium.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Medium.woff") format("woff"),
    url("./assets/fonts/SFProDisplay-Medium.ttf") format("truetype"),
    url("./assets/fonts/SFProDisplay-Medium.svg#SFProDisplay-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/SFProDisplay-Regular.eot");
  src: url("./assets/fonts/SFProDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/SFProDisplay-Regular.woff2") format("woff2"),
    url("./assets/fonts/SFProDisplay-Regular.woff") format("woff"),
    url("./assets/fonts/SFProDisplay-Regular.ttf") format("truetype"),
    url("./assets/fonts/SFProDisplay-Regular.svg#SFProDisplay-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
body {
  margin: 0;
  padding: 0;
  font-family: "SF Pro Display";
  font-weight: 400;
  background-color: #fcfdfd;
  color: #3e3e3e;
  overflow-x: hidden;
  position: relative;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilroy";
  font-weight: bold;
}
a {
  color: #00E599;
  text-decoration: none;
}
a:hover {
  color: #00E599;
  text-decoration: none;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);  
}
*::-webkit-scrollbar-thumb {
  background-color: #00E599;
}
img.brand_logo {
  width: 180px;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #f9f9fa;
  margin-top: 0 !important;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0 !important;
  z-index: 999;
}
.navbar-sticky--on {
  margin-top: 0;
}
.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
/* ul.navbar_left {
  margin-left: 26%;
} */
.bal {
  color: #fff;
  font-size: 16px;
  font-family: "Gilroy";
  font-weight: 600;
  line-height: normal;
}
.main_navbar a.nav-link {
  color: #fff;
  font-size: 16px;
  font-family: "Gilroy";
  font-weight: 600;
  line-height: normal;
}
.main_navbar a.nav-link.active,
.main_navbar a.nav-link:hover {
  color: #00E599 !important;
  text-decoration: none;
}
.main_wrapper {
  min-height: 100vh;
}
.innerpages_wrapper {
  min-height: 100vh;
  margin: 50px 0;
  position: relative;
}
.main_navbar {
  margin-top: 0px;
  border-radius: 0px;
  border: 1px solid transparent;
  background: #003;
  backdrop-filter: blur(4.5px);
  padding: 12px 20px;
  z-index: 999;
  justify-content: space-between;
}
.navbar-sticky--moved-up {
  background: #003 !important;
}
.inner_title_wrapper {
  margin: 35px 0;
}
.inner_title {
  color: #333;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
}
.dash_wrapper {
  border-radius: 50px !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4.5px);
  padding: 40px;
  margin-bottom: 30px;
}
.dashwrapper1{
  border: 1px solid #b3adad !important;
}
.dash_box {
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4.5px);
  border: 1px solid rgb(223, 223, 223);
  padding: 30px;
  margin-bottom: 24px;
}
.dash_box_left_flex {
  display: flex;
  align-items: center;
}
.dash_box_left_flex > div {
  width: 100%;
}
.dash_box_left h3 {
  color: #212529;
  text-align: right;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.dash_box_left h4 {
  color: #626262;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.dash_box_right_flex {
  display: flex;
  align-items: center;
}
.dash_box_right_flex > div {
  width: 100%;
}
.dash_box_right h3 {
  color: #212529;
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.dash_box_right h4 {
  color: #626262;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.tradingview-widget-container {
  height: 100%;
}
.tradingview-widget-container > div {
  height: 100%;
}
.time_card {
  min-width: 60px;
  width: 20%;
  padding: 8px 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.count_value {
  font-size: 32px;
  line-height: 38px;
}
.count_indicator {
  font-size: 12px;
  font-weight: 600;
}
.counter {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  background-blend-mode: overlay;
}
.px3 {
  padding-right: 3rem !important;
}
.affiliate_inpgrp {
  position: relative;
  margin: 14px 0;
}
.affiliate_inpgrp input {
  border-radius: 20px;
  background: #f5f5f7;
  border: 1px solid #f5f5f7;
  padding: 8px 12px;
  color: #212529;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.affiliate_list li a {
  background: #b4b4b4;
  color: #fff;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s all;
}
.affiliate_list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}
.affiliate_list li a:hover {
  background: #00E599;
}
.affiliate_list li a svg {
  fill: #fff;
}
.copy_icon {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.txt_green {
  color: #00E599 !important;
}
.txt_red {
  color: #ff6363 !important;
}
.dash_box_right_top {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
  margin-bottom: 12px;
}
.dash_box_right_top img {
  border-radius: 100px;
  width: 30px;
}
.dash_box_right_top h4 {
  font-size: 16px;
  font-weight: 600;
}
.flex-dashbox-top b span {
  font-size: 16px;
}
.apy_tooltip {
  width: 250px !important;
}
.custom_tooltip {
  background: #fff !important;
  color: #626262 !important;
  border: 1px solid #ebebeb;
  box-shadow: 0 4px 8px rgba(40, 40, 40, 0.08);
  opacity: 1 !important;
  z-index: 99;
}
.dash_box_right_bottom > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.dash_box_right_bottom .img_box {
  height: 170px;
  justify-content: center;
}
.dash_box_right_bottom .img_box img {
  max-height: 150px;
}
.dash_box_right_bottom > div label:nth-child(2) {
  font-weight: 500;
}
.dash_box_right_bottom h2 {
  color: #212529;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}
.dash_box_right_bottom h2 span {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.primary_btn {
  padding: 6px 20px;
  font-size: 16px;
  text-transform: capitalize;
  color: #000033;
  border: 1px solid #00e599;
  border-radius: 20px;
  background: #00e599;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  font-family: "Gilroy";
  font-weight: 600;
}
.primary_btn1 {
  background: #fff;
  border: 1px solid #fff;
  margin-bottom: 20px;
}
.primary_btn:hover {
  border: 1px solid #00e599;
  background-color: transparent;
  box-shadow: 0px 8px 20px 0px #00e5995d;
  color: #00e599;
}
.primary_btn1:hover {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  box-shadow: 0px 8px 20px 0px #ffffff5d;
}
.primary_btn_transform:hover {
  transform: translateY(-7px);
}
.button_grp {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.custom_progress {
  position: relative;
}
.progress_value {
  position: absolute;
  top: -7px;
  right: 0;
  color: #00E599;
  font-size: 14px;
  font-weight: 700;
}
.custom_progress .progress {
  border-radius: 10px;
  background: #ececef;
  height: 10px;
}
.custom_progress .progress,
.progress_note {
  width: 95%;
}
.progress_note {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.progress_note label {
  color: #9d9d9d;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.progress_note label:nth-child(2) {
  color: #626262;
  font-weight: 700;
}
.custom_progress .progress-bar {
  background: #00E599;
  border-radius: 10px;
}
.dash_box_right h5 {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}
.dash_box_right_large {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.dash_box_right_large_single {
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
}
.dash_box_right_large_single h3 {
  color: #212529;
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.dash_box_right_large_single::after {
  content: "";
  background: #e0e0e0;
  width: 1px;
  height: 63px;
  position: absolute;
  right: -42px;
  top: 0;
}
.dash_box_right_large_single:last-child::after {
  display: none;
}
.dash_box_right_large_single h5 {
  margin-bottom: 18px;
}
.dash_box.dash_box_right.dash_box_right_large {
  padding-top: 50px;
  padding-bottom: 50px;
}
.dash_box.dash_box_right {
  padding-top: 36px;
  padding-bottom: 36px;
}
.inner_subtitle_wrap h3 {
  color: #00E599;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}
.inner_subtitle_wrap_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.primary_datatable .rdt_TableHeadRow .rdt_TableCol {
  font-size: 16px;
  font-weight: 500;
  color: #212529;
}
.primary_datatable .rdt_Table,
.primary_datatable .rdt_Table > div {
  background: transparent;
}
.primary_datatable .rdt_TableBody .rdt_TableRow,
.primary_datatable .rdt_TableHeadRow {
  border-bottom: 0;
  background: transparent;
}
.primary_datatable .rdt_Pagination {
  border-top: 0;
  background: transparent;
}
.primary_datatable .rdt_TableBody .rdt_TableRow:nth-child(odd) {
  background:#00e5991c;
}
.primary_datatable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  text-transform: capitalize;
}
.primary_datatable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
}

.primary_datatable_chg
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell:nth-last-child(1)
  > div {
  overflow: visible;
  white-space: unset;
}
.footer {
  border: 1px solid transparent;
  background: #003;
  backdrop-filter: blur(4.5px);
  padding: 30px 0;
  position: relative;
  z-index: 1;
}
.footer_panel_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}
.footer_panel_bottom p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.footer_panel_bottom p a {
  color: #00E599;
  font-weight: 500;
}
.footer_menu_links {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 30px;
  margin: 0;
}
.footer_menu_links li {
  position: relative;
}
.footer_menu_links li::after {
  content: "";
  background: #898989;
  width: 1px;
  height: 15px;
  position: absolute;
  top: 6px;
  right: -15px;
}
.footer_menu_links li:last-child:after {
  display: none;
}
.footer_menu_links li a {
  color: #3e3e3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
p.footer_abt_content {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.footer_panel_top h3 {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.42px;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.footer_panel_top .navbar-brand img {
  width: 182px;
  margin-bottom: 20px;
}
.footer_quick_links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: end;
  gap: 10px;
  flex-wrap: wrap;
}
.footer_quick_links li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.03px;
  text-transform: capitalize;
  font-family: "Inter";
}
.footer_quick_links li a:hover{
  color: #00b881;
}
.footer_quick_links li:not(:last-child)::after {
  content: "|";
  margin-left: 5px;
  margin-right: 5px;
  color: white;
  padding: 5px;
}
.footer_social_links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0;
  margin: 0;
}
.footer_social_links li a {
  background: #191947;
  color: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.2s all;
}
.footer_social_links li a:hover {
  background: #00e599;
}
.footer_social_links li a:hover svg {
  fill: #003;
}
.footer_social_links li a svg {
  fill: #fff;
}
.footer_panel_top {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.auth_btn_grp {
  display: flex;
  gap: 15px;
}
.offcanvas-header .btn-close {
  box-shadow: none;
  filter: invert(1);
}
.toolbar_bottom {
  display: none;
}
.page_header {
  position: relative;
  z-index: 1;
}
.page_header::before {
  content: "";
  background: url("../src/assets/images/banner_bg_dark.png") no-repeat bottom right;
  background-size: cover;
  width: 100%;
  height: 901px;
  position: absolute;
  top: -150px;
  right: 0;
  z-index: -1;
}


.close_trade_info {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 0;
}
.close_trade_info > div {
  display: flex;
  flex-direction: column;
  flex: 50% 0 0;
}
.close_trade_info > div label {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
}
.close_trade_info > div label:nth-child(2) {
  color: #212529;
  font-weight: 500;
}

/* body::after {
  content: "";
  background: url("../src/assets/images/footer_bg.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 449px;
  position: absolute;
  bottom: 0;
  z-index: -1;
} */
.page_header_inner {
  /* border-radius: 20px; */
  /* border: 1px solid rgba(255, 255, 255, 0.75);
  background: rgba(255, 255, 255, 0.5); */
  /* backdrop-filter: blur(4.5px);
  padding: 89px 57px 89px 56px; */
  margin-top: 50px;
  text-align: center;
}
.page_header_inner {
  padding-top: 40px;
}
.page_header_inner img {
  margin-bottom: 30px;
  height: 400px;
}
.primary_btn_with_bg {
  background: #00E599;
  color: #fcfdfd;
  box-shadow:0px 8px 20px 0px rgb(0 229 153 / 26%);
}
.primary_btn_with_bg:hover {
  background: #fcfdfd;
  color: #00E599;
}
.page_header h1 {
  color: #fff;
  font-weight: 600;
  font-size: 40px;
  text-transform: uppercase;
  margin: auto;
  margin-bottom: 30px;
  max-width: 67%;
}
.page_header h1 span {
  color: #00e599;
}
.page_header p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 30px;
  z-index: 10;
  position: relative;
  max-width: 38%;
  margin-right: auto;
  margin-left: auto;
}
.main_wrapper {
  min-height: 100vh;
  z-index: 1;
  position: relative;
}
.section {
  padding: 80px 0;
}
.countdown_single {
  border-radius: 20px;
 
  border: 1px solid #ffffffd6;
  background: #eae5e5;
  /* backdrop-filter: blur(4px); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  padding: 63px 45px;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.countdown_single:hover {
  border: 1px solid #f3f3f3;
  background: #f3f3f3;
}
/* .countdown_single:hover{
  background: rgba(255, 230, 201, 0.5);
} */
.countdown_single::before {
  content: "";
  background: url(./assets/images/countdownicon4.png);
  width: 155px;
  height: 175px;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
}

.countdown_single:nth-of-type(2)::before {
  content: "";
  background: url(./assets/images/countdownicon5.png);
  width: 162px;
  height: 175px;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
}
.countdown_single:nth-of-type(3)::before {
  content: "";
  background: url(./assets/images/countdownicon6.png);
  width: 170px;
  height: 175px;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
}
.countdown_panel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.countdown_single h3 {
  color: #000033;
  font-size: 64px;
  font-weight: 700;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 20px;
}
.countdown_single p {
  color: #1e1e1e;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.countdown_single:nth-child(2) h3 {
  color: #000033;
}
.countdown_single:nth-child(3) h3 {
  color: #00E599;
}
.countdown_section {
  padding: 0;
  margin-top: 80px;
  z-index: 10;
  position: relative;
}
.Staking_operates h2 {
  font-weight: 600;
  font-size: 36px;
  text-transform: uppercase;
  color: #003;
}
.Staking_operates h2 span {
  color: #00e599;
}
.Staking_operates_btn {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 80px;
}
.Staking_operates_btn .btn {
  padding: 17px 25px;
  background: transparent;
  border: 1px solid #cbc6c6;
  border-radius: 10px;
  font-weight: 400;
  font-size: 22px;
  color: #3E3E3E;
  text-align: start;
}
.Staking_operates_content {
  display: flex;
  align-items: center;
  gap: 32px;
  border: 1px solid #cbc6c6;
  padding: 48px;
  margin-left: 90px;
  margin-bottom: 40px;
  border-radius: 30px;
}

.Staking_operates_content:hover {
  background-color: #00e599;
}
.Staking_operates_content:hover img {
  filter: brightness(0) invert(1) grayscale(1);
}
.Staking_operates_content:hover p {
  color: white;
}
.Staking_operates_btn button {
  background-color: initial; /* Set default background color */
}

.Staking_operates_btn button.active1 {
  background-color: #00e599; /* Change background color for active button */
  color: #fff !important;
}
.Staking_operates_content.active1 img {
  filter: brightness(0) invert(1) grayscale(1);
}
.Staking_operates_content.active1 p {
  color: white;
}
.active1 {
  background-color: #00e599;
}

.Staking_operates_content p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #3E3E3E;
}
.main_title {
  color: #003;
  font-weight: 600;
  font-size: 36px;
  line-height: 133%;
  text-transform: uppercase;
  text-align: center;
  margin: auto;
  max-width: 50%;
}
.main_title span {
  color: #00b881;
}
.main_para {
  color: #3e3e3e;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 30px;
}
.main_para b {
  color: #1e1e1e;
  font-weight: 500;
}
.features_panel {
  border-radius: 60px;
  background: #dafff2;
  padding: 50px 80px;
}
.features_panel h3 {
  color: #00E599;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin: 30px 0;
}
.features_panel p.main_para {
  max-width: 85%;
}
.staking_works {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 30px;
}
.staking_works h2 {
  color: #1e1e1e;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
}
.staking_calc {
  border-radius: 40px;
  background: #fefefe;
  box-shadow: 0px 15px 25px 0px #e9e9e9;
  padding: 40px;
}
.faq_accordian {
  background: transparent; 
}
.faq_accordian button {
  box-shadow: none !important;
}
.faq_accordian .accordion-item {
  border: none;
  border-bottom: 1px solid #00b88152;
  border-radius: 0;
  background: transparent;
}
/* .faq_accordian .accordion-item:last-child {
  border-bottom: 0;
} */
.faq_accordian .accordion-item button {
  background: transparent;
  color: #212529;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  padding: 15px 0;
}
.faq_accordian .accordion-button::after {
  background: url("../src/assets/images/faq_icon.png") no-repeat;
  width: 10px;
  height: 6px;
  /* transform: rotate(270deg); */
}
.faq_accordian .accordion-button:not(.collapsed)::after {
  transform: rotate(180deg);
}
.faq_accordian .accordion-body {
  padding: 10px 0 15px;
}
.faq_accordian .accordion-body .main_para {
  margin-bottom: 0;
  max-width: 90%;
}
.image_dropdown {
  width: 100%;
  text-align: left;
  border-radius: 20px;
  background: #f3f3f3 url("../src/assets/images/select_dropdown.png") no-repeat
    center right 15px !important;
  border: 1px solid #b3aeae  !important;
  height: 60px;
  padding: 10px 15px;
  color: #1e1e1e !important;
  font-size: 16px;
}
.form-text {
  font-weight: 400;
  font-size: 16px;
}
.dropdown-toggle.image_dropdown::after {
  display: none;
}
/* .image_dropdown_wrapper.dropdown {
  border: 1px solid #f3f3f3;
  padding: 15px 10px;
  border-radius: 20px;
} */
.image_dropdown img,
.image_dropdown_wrapper .dropdown-menu img {
  margin-right: 10px;
}
.image_dropdown_wrapper .dropdown-menu {
  width: 100%;
  padding: 0;
  height: 330px;
  overflow-y: auto;
  background: #fff;
}
.image_dropdown_wrapper .dropdown-menu .dropdown-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.image_dropdown_wrapper .dropdown-menu .dropdown-item.active,
.image_dropdown_wrapper .dropdown-menu .dropdown-item:active,
.image_dropdown_wrapper .dropdown-menu .dropdown-item:focus,
.image_dropdown_wrapper .dropdown-menu .dropdown-item:hover {
  background: rgb(0 184 129 / 80%);
  color: #fff;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.staking_calc input {
  height: 60px;
  border-radius: 20px;
  background: #f3f3f3 !important;
  border: 1px solid #959595  !important;
  padding: 10px 15px;
  color: #1e1e1e !important;
  font-size: 16px;
  box-shadow: none !important;
}
.staking_calc .input-group .primary_asset_inp {
  height: 60px;
  font-size: 30px;
  border-radius: 20px;
  border: 0 !important;
  background: #00E599 !important;
}
.staking_calc .input-group1 .primary_asset_inp{
  background: transparent !important;
}
.staking_calc .input-group1 input{
  background: transparent !important;
  border: transparent !important;
  border-left: 1px solid #b3aeae !important;
  border-right: 1px solid #b3aeae !important;
}
.staking_calc .input-group1{
  border: 1px solid #b3aeae ;
  border-radius: 20px;
  background: #f3f3f3;
}
.MuiSlider-root {
  color: #00E599 !important;
}
.staking_calc label {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3e3e3e !important;
}
.staking_calc [class*="col-"] {
  margin-bottom: 30px;
}
.inactive {
  display: none;
}
.staking_method_btngrp {
  display: flex;
  gap: 10px;
}
.modal_staking_period .staking_method_btngrp {
  flex-wrap: wrap;
}
.modal_staking_period .staking_method_btngrp button {
  width: auto;
  height: auto;
  padding: 7px 20px;
}
.staking_method_btngrp button {
  color: #003;
  font-family: "Gilroy";
  font-weight: 400;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #a99c9c;
  background: transparent;
  /* padding: 10px 20px; */
  white-space: nowrap;
  width: 100%;
  height: 70px;
}
.staking_method_btngrp button.active {
  background: #00e599 !important;
  border: 1px solid #00e599 !important;
  color: #fff !important;
}

.staking_result_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 80px;
  border-radius: 20px;
  border: 1px solid #c1bdbd;
  background: rgba(255, 255, 255, 0.15);
}
.staking_result_div p {
  color: #3e3e3e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 14%;
  margin-bottom: 0;
}
.staking_result_div h3 {
  color: #1e1e1e;
  font-size: 40px;
  line-height: normal;
  font-weight: 400;
}
.staking_result_div span {
  color: #b7b7b7;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
.auth_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 120px 0;
  position: relative;
}
.auth_wrapper .dash_box {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4.5px);
  position: relative;
  padding: 50px 80px;
  z-index: 1;
}
.login-page .dash_box{
  border: 1px solid #00E599;
}
.register-page .dash_box{
  border: 1px solid #00E599;
}
.forgot-page .dash_box{
  border: 1px solid #00E599;
}
/* .innerpages_wrapper::before {
  content: "";
  background: url("../src/assets/images/banner_bg.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -120px;
  z-index: -1;
} */
.auth_form input.form-control {
  background: #00e5991c!important;
  border: 1px solid #00E599 !important;
  backdrop-filter: blur(2.5px);
  border-radius: 50px;
  height: 56px !important;
  padding-left: 25px;
  box-shadow: none !important;
  color: #626262;
}
.auth_form input.form-control::placeholder {
  color: #201f1fd5;
}
.auth_form .form-check-input:checked {
  background-color: #00E599;
  border-color: #00E599;
  box-shadow: none !important;
}
.auth_form .form-check-input:focus {
  box-shadow: none !important;
}
.auth_form .primary_btn_with_bg {
  padding: 12px 18px;
  font-size: 18px;
}
ul.nav_after_login {
  display: flex;
  align-items: center;
  gap: 20px;
}
.nav_after_login li {
  position: relative;
}
.nav_after_login li a.nav-link {
  color: #fff !important;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  fill: #fff !important;
}
.nav_after_login .dropdown svg {
  margin-right: 10px;
}
.notify_active {
  background: #d22102;
  border: 2px solid #fff;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  position: absolute;
  right: -6px;
  top: -4px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.nav_after_login li a.dropdown-item {
  color: #1e1e1e;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
}
.nav_after_login li a.dropdown-item:focus,
.nav_after_login li a.dropdown-item:hover {
  color: #fff;
  background-color: #00E599;
}
.level_top_row .dash_box_right {
  padding-top: 20px !important;
  padding-bottom: 0 !important;
  height: 100%;
}
.level_top_row .dash_box_left {
  height: 100%;
  padding: 10px 30px;
}
.level_top_row [class*="col-"] {
  margin-bottom: 24px;
}
.level_head,
.level_body_row {
  display: flex;
}
.level_head > div:nth-child(1),
.level_body_row > div:nth-child(1) {
  width: 25%;
  margin-right: 80px;
}
.level_head > div:nth-child(2),
.level_body_row > div:nth-child(2) {
  width: 40%;
}
.level_head > div:nth-child(3),
.level_body_row > div:nth-child(3) {
  width: 20%;
}
.level_head > div {
  color: #00E599;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.level_body_row > div {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
.level_body_row {
  background: #f0f0f0;
  height: 56px;
  margin-bottom: 40px;
  align-items: center;
  border-radius: 30px;
}
.level_ribbon {
  position: relative;
  background: url("../src/assets/images/level_default.png") left center
    no-repeat;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00E599;
}
.level_ribbon.active {
  background: url("../src/assets/images/level_active.png") left center no-repeat;
  color: #fff;
}
.level_table {
  margin-top: 50px;
}
.level_head {
  padding-bottom: 30px;
}
.asset_dashbox_flex {
  display: flex;
  align-items: flex-start;
  gap: 60px;
}
.asset_dashbox_flex_line {
  position: relative;
}
.asset_dashbox_flex_left,
.asset_dashbox_flex_right {
  width: 50%;
}
.asset_dashbox_flex_left{
  fill: #00b881;
  }
.asset_dashbox_flex_line::after {
  content: "";
  width: 1px;
  height: calc(100% - 60px);
  background: #e0e0e0;
  position: absolute;
  right: 50%;
}
.asset_deposit_innertitle {
  color: #00E599;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
.primary_warning_alert {
  border-radius: 29px;
  background: #f5f5f7;
  border-color: #f5f5f7;
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.primary_warning_alert svg {
  min-width: 26px;
  height: 26px;
}
.primary_warning_alert h3 {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.primary_warning_alert p {
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.deposit_notes_list {
  color: #626262;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  padding-left: 15px;
}
.deposit_notes_list li {
  margin-bottom: 15px;
}
.asset_deposit_grp {
  position: relative;
}
.asset_deposit_grp input {
  padding-right: 40px;
}
.primary_asset_inp {
  height: 45px;
  border-radius: 30px;
  background: #00e5991c!important;
  border-color: #00E599 !important;
  padding: 10px 20px;
  box-shadow: none !important;
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.green-form{
  background-color: #00e5991c !important;
}
.asset_copy_icon {
  position: absolute;
  top: 15px;
  right: 18px;
  cursor: pointer;
}
.deposit_asset_details > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deposit_asset_details > div label {
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  text-transform: capitalize;
}
.deposit_asset_details > div label:nth-child(2) {
  color: #212529;
  font-weight: 500;
}
.inner_title_wrapper_flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.assets_form label {
  color: #626262;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  display: block;
}
.withdrawal_type {
  color: #00E599;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  right: 18px;
}
.deposit_asset_details > div.font_bold {
  margin-top: 30px;
}
.deposit_asset_details > div.font_bold label {
  color: #0b0e11;
  font-weight: 600;
}
label.max_label {
  color: #00E599;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  margin-bottom: 8px;
}
.transfer_modal .modal-dialog {
  max-width: 700px;
}
.primary_modal .modal-header h1 {
  color: #1e1e1e;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}
.primary_modal .modal-header .btn-close {
  box-shadow: none !important;
}
.asset_modal_btn_grp {
  display: flex;
  gap: 24px;
}
.primary_asset_select {
  height: 45px;
  border-radius: 30px;
  border-color: #00E599 !important;
  background:  url("../src/assets/images/select_dropdown.png") no-repeat;
  background-position: right 0.75rem center;
  background-size: 14px 9px !important;
  padding: 10px 20px;
  box-shadow: none !important;
  color: #626262;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.asset_transfer_flex {
  display: flex;
  align-items: center;
  gap: 20px;
}
.trade_user_detail {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}
.trade_user_detail h3 {
  color: #212529;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.96px;
  margin-bottom: 0;
}
.trade_user_detail img {
  box-shadow: 0 15px 25px 0 #e9e9e9;
  border-radius: 100px;
  width: 72px;
  height: 72px;
}
.copy_trade_row .dash_box {
  height: 100%;
}
.copy_trade_row [class*="col-"] {
  margin-bottom: 24px;
}
.cusform_form_switch .form-check-input {
  box-shadow: none !important;
  border-color: #d7d7d7;
}
.cusform_form_switch .form-check-input:checked {
  background-color: #00E599;
  border-color: #00E599;
}
.primary_tab {
  display: flex;
  gap: 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid #efefef;
}
.primary_tab li button.nav-link {
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  color: #afafaf;
  background: transparent;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 500;
}
.primary_tab li button.nav-link.active {
  border: 1px solid #d9d9d9;
  background: #d9d9d9;
  color: #0b0e11;
}
.notification_panel_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  padding: 30px 0;
  cursor: pointer;
}
.notification_panel_row:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.unread_dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-top: 6px;
  background: #00E599;
  position: absolute;
}
.read_dot {
  background: #fff;
}
.notification_row_content {
  margin-left: 15px;
}
.notification_row_content h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.notification_row_content p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}
.notification_panel_row p.notify_date {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  color: black;
}
.notification_panel_row_empty > div {
  width: 100%;
  align-items: center;
}
.notification_panel_row > div {
  display: flex;
  align-items: flex-start;
  width: 65%;
}
.no_orders {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center !important;
  flex-direction: column;
  width: 100% !important;
}
.user_info {
  display: flex;
  align-items: center;
  gap: 30px;
}
.user_info img {
  box-shadow: 0 15px 25px 0 #e9e9e9;
  border-radius: 100px;
  width: 72px;
  height: 72px;
}
.user_info_content h3 {
  color: #0b0e11;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}
.user_info_content h5 {
  display: flex;
  align-items: center;
  gap: 30px;
}
.user_info_content h5 span {
  color: #626262;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}
.user_info_content h6 {
  color: #999999;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.user_info_content h6 span {
  cursor: pointer;
}
.center_line {
  margin: 0 5px;
  font-size: 10px;
}
.custom_file_upload {
  position: relative;
  overflow: hidden;
  background: #00E599 !important;
  padding: 8px 30px;
  color: #fff !important;
}
.custom_file_upload input[type="file"] {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.alertCard h3 {
  font-weight: 700;
}
.refer-icon {
  height: 16px;
}
.custom_nav_tabs {
  gap: 10px;
}
.flex-dashbox-top {
  justify-content: space-between;
}
.crypto-img-name {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.custom_nav_tabs button.nav-link {
  color: #00E599;
  font-family: "Gilroy";
  font-weight: 600;
  border-radius: 20px;
  border: 1px solid #00E599;
  background: transparent;
  padding: 12px 20px;
  white-space: nowrap;
  min-width: 150px;
}
.custom_nav_tabs button.nav-link.active {
  background: #00E599;
  color: #fff;
}
.staking_top_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.search_grp {
  position: relative;
}
.search_grp input {
  padding: 12px 20px;
  box-shadow: none !important;
  border-radius: 20px;
  background: #00e5991c!important;
  border-color: #00E599 !important;
  width: 300px;
}
.search_icon {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 18px;
}
.input-group .primary_asset_select {
  flex: 0 1 auto;
  width: 140px;
}
.alert-success {
  color: #1e1e1e;
  background: rgba(0, 184, 129, 0.3);
}
.wallet_table {
  background: transparent;
}
.wallet_table th {
  font-size: 16px;
  font-weight: 500;
  color: #212529;
  background: transparent;
  border: none;
  padding: 10px 30px;
}
.wallet_table td {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
  vertical-align: middle;
  border: none;
  white-space: nowrap;
  background: transparent;
  padding: 10px 30px;
}
.table_asset {
  display: flex;
  align-items: center;
  gap: 10px;
}
.table_asset img {
  width: 36px;
}
.table_asset span {
  font-size: 15px;
  font-weight: 400;
  color: #626262;
}
.wallet_table td:last-child {
  width: 300px;
}
.wallet_table tbody tr:nth-child(odd) {
  background: #00e5991c;
}
.wallet_bal {
  display: flex;
  align-items: center;
  gap: 10px;
}
.wallet_bal h4 {
  margin-bottom: 0;
  font-size: 18px;
}
.wallet_page_right_flex {
  display: flex;
  align-items: center;
  gap: 15px;
}
.wallet_page_right_flex .form-check-input {
  box-shadow: none !important;
}
.wallet_page_right_flex .form-check-input:checked {
  background-color: #00E599 !important;
  border-color: #00E599 !important;
}
.dash_wrapper.dash_wrapper_flex {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  position: relative;
  border: 1px solid #b3adad;
}
.dash_wrapper_flex_left,
.dash_wrapper_flex_right {
  width: 50%;
}
.dash_wrapper_flex::after {
  content: "";
  width: 1px;
  height: calc(100% - 60px);
  background: rgba(0, 184, 129, 0.3);
  position: absolute;
  right: 50%;
}
textarea.form-control.primary_asset_inp {
  height: auto;
  resize: none;
}
textarea.form-control.primary_asset_inp{
  background-color: white !important;
}
.uploaded_files_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ecf9f5;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
}
.uploaded_files_row > div span {
  font-size: 12px;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 5px;
}
.dash_wrapper_flex_left .primary_btn {
  padding: 15px 20px;
}
.dash_wrapper_flex_right .search_grp input {
  width: 100%;
}
.dash_wrapper_flex .inner_subtitle_wrap_flex {
  margin-bottom: 15px;
}
.support_ticket_accordian .accordion-header .accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
  background: transparent !important;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}
.support_ticket_accordian .accordion-header .accordion-button > div {
  display: flex;
  gap: 20px;
  margin-right: 30px;
}
.support_ticket_accordian .accordion-button::after {
  position: absolute;
  margin-left: unset;
  right: 0;
}
.ticket_msg_card_user {
  width: 85%;
}
.ticket_msg_card {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(160, 160, 160, 0.35);
  padding: 30px;
  margin: 5px;
  margin-bottom: 24px;
}
.ticket_msg_card_header {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}
.ticket_msg_card_admin {
  width: 85%;
  margin-left: auto;
}
.ticket_msg_card_header h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.ticket_msg_card_header p {
  color: #5e5e5e;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}
.ticket_msg_card_desc p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}
.ticket_msg_card i {
  font-size: 24px;
}
.chat_reply {
  position: relative;
}
.chat_reply .primary_asset_inp {
  padding-right: 50px;
}
.chat_reply i {
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
  color: #00E599;
  font-size: 20px;
}
.faq_accordian .accordion-item button.primary_btn {
  width: auto;
  padding: 10px 30px;
}
.faq_accordian .accordion-item .asset_modal_btn_grp {
  justify-content: flex-end;
}
.ticket_msg_card_wrapper {
  height: 500px;
  overflow: auto;
  padding-right: 10px;
  margin-bottom: 15px;
}
.stake_modal .staking_method_btngrp button {
  height: auto;
  padding: 5px 12px;
}
.deposit_asset_details > div.modal_staking_period {
  flex-direction: column;
  align-items: flex-start;
}

.phone_num .form-control {
  width: 100% !important;
  padding-left: 40px !important;
  background-color: transparent !important;
  border: 0;
}
.phone_num .react-tel-input .selected-flag:hover,
.phone_num .react-tel-input .selected-flag:focus {
  background-color: transparent;
}
.phone_num .react-tel-input .flag-dropdown {
  background-color: transparent;
  border: 0;
}
.phone_num .react-tel-input .flag-dropdown.open {
  background: transparent;
}
.phone_num .react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent;
}
.ticker-sec {
  background: #000033;
}
.ticker_panel {
  border-radius: 20px;
  background: #fff !important;
  border: 1px solid #00E599 !important;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px;
  margin: 0 15px;
}
.ticker_panel img {
  width: 56px;
  height: 56px;
}
.ticker_content h3 {
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 0;
  line-height: 30px;
}
.ticker_content h4 {
  margin-bottom: 0;
  font-family: "SF Pro Display";
  font-weight: 400;
  font-size: 24px;
  display: flex;
  gap: 20px;
}
.ticker_settings .slick-prev,
.ticker_settings .slick-next {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.ticker_settings .slick-prev:before,
.ticker_settings .slick-next:before {
  content: "";
  background: url(./assets/images/nav_arrow.png) no-repeat center !important;
  background-color: #00e599 !important;
  width: 17px;
  height: 14px;
  position: absolute;
  padding: 20px;
  border-radius: 50%;
}
.ticker_settings .slick-prev:before {
  transform: rotate(180deg);
}
.rewards-sec h2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 49px;
  text-transform: uppercase;
  margin: auto;
  text-align: center;
  color: #003;
  max-width: 67%;
}
.rewards_content_head {
  display: flex;
  gap: 24px;
}
.rewards-sec h2 span {
  color: #00e599;
}
.rewards_content {
  border: 1px solid #cac6c6;
  padding: 40px;
  border-radius: 20px;
}
.rewards_content:hover {
  background-color: #f3f3f3;
}
.rewards_content h6 {
  font-weight: 400;
  font-size: 20px;
  color: #003;
  padding: 20px 0;
  margin-bottom: 0;
}
.rewards_content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3e3e3e;
  margin-bottom: 0;
}
.auth_form span.withdrawal_type {
  top: 18px;
  font-size: 20px;
  color: #00E599;
}
.staking_calc.trade_buy {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
  background: transparent;
}
.staking_calc.trade_buy .image_dropdown_wrapper .dropdown-menu {
  height: 200px;
}
.staking_calc.trade_buy .form-group {
  margin-top: 0;
  margin-bottom: 15px;
}
.staking_calc.trade_buy label {
  margin-bottom: 3px;
}
.rc-slider-dot {
  bottom: -5px !important;
  width: 14px !important;
  height: 14px !important;
}
.rc-slider {
  padding-left: 30px;
  padding-right: 30px;
}
.rcslider_custom {
  padding: 0 10px;
}
.staking_method_btngrp button.trade_btn {
  height: auto;
  padding: 8px;
  border: 2px solid #fff;
  background: #fff;
}
button.trade_buy.trade_btn {
  background: #00E599;
  border: 1px solid #00E599;
  color: #fff;
}
button.trade_sell.trade_btn {
 background: #d53d3d;
  border: 1px solid #d53d3d;
  color: #fff;
}
/* button.trade_buy.trade_btn.active {
  background: #00E599 !important;
  border: 1px solid #00E599;
  color: #fff;
}*/
button.trade_sell.trade_btn.active {
  background: #d53d3d !important;
  border: 1px solid #d53d3d !important;
  color: #fff;
} 
.staking_calc.trade_buy .staking_method_btngrp {
  margin-bottom: 15px;
}
.staking_calc.trade_buy button.primary_btn {
  margin-top: 30px;
  padding: 10px;
  font-size: 18px;
  background: #00E599;
  border: 1px solid #00E599;
  color: #fff !important;
}
button.primary_btn.primary_sell_btn {
  border: 1px solid #d53d3d !important;
  background: #d53d3d !important;
}
.dash_box.trade_top_info {
  padding: 10px 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}
.trade_top_info > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.trade_top_info > div label {
  font-weight: 500;
}
.trade_top_info > div label:nth-child(2) {
  color: #00E599;
}
.gap-10 {
  gap: 0 10px;
}

.footer-links-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-links-card a{
  margin-bottom: 12px;
}
.footer-links-card a:hover{
  color: #aa31f8;
}
/* .footer-links-card{
  align-items: center;
  justify-content: center;
  text-align: center;
} */

.crypto_box {
  background-color:#00e5991c;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #00E599;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 5px;
}
.crypto_box p {
  margin-bottom: 0;
  font-weight: 500;
  color: #000000;
}
.crypto_box span {
  font-size: 15px;
}
.copy_trade_row .slick-prev:before,
.copy_trade_row .slick-next:before {
  background-image: url("./assets/images/nav-arrow1.png");
  background-repeat: no-repeat;
  width: 10px;
  height: 17px;
  font-size: 0;
  display: block;
  fill: #00b881 ;
}
.copy_trade_row .slick-prev:before {
  transform: rotate(360deg);
}
.copy_trade_row .slick-next:before {
  transform: rotate(180deg);
}
.coins_box > div {
  margin: 5px 0;
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}
.coins_box > div p {
  margin-bottom: 0;
  text-transform: uppercase;
}
.coins_box > div p.text-primary {
  color: #00E599 !important;
}
.coins_box .accordion-item {
  margin-bottom: 12px !important;
  border-radius: 10px !important;
  background:#00e5991c;
  border: 1px solid #00E599;
}
.coins_box .accordion-item .accordion-button {
  border-radius: 10px !important;
  background: #00e5991c;
  color: #3e3e3e;
  box-shadow: none !important;
}
.support_ticket_accordian .accordion-button::after {
  filter: contrast(0) brightness(0) !important;
}
.menu-list div {
  padding: 12px 0;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 10px;
}
.menu-list div:hover {
  background: #00E599;
  color: #ffffff;
}
.menu-list div img {
  margin-right: 7px;
}
.navbar_right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.toggletheme_btn {
  border: none;
  background: transparent;
  color: #fff;
}
.react-tel-input .form-control {
  height: 45px !important;
  border-radius: 30px !important;
  background: #233981!important;
  border-color: #4D6CCF !important;
  box-shadow: none !important;
  color: #626262;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
}
.phone-number .form-control{
  background: #00e5991c !important;
    border-color: #00E599 !important;
}
.modal-header .btn-close {
  box-shadow: none !important;
}
.input-group span.primary_asset_inp {
  border: 1px solid;
}
.custom_pagination {
  justify-content: center;
  margin-top: 30px;
}
.custom_pagination .active > .page-link,
.custom_pagination .page-link.active {
  background-color: #00E599;
  border-color: #00E599;
  color: #fff;
}
.custom_pagination .page-link {
  color: #090808;
  box-shadow: none !important;
}
.blog_title {
  font-size: 20px !important;
  font-weight: 700 !important;
}
.blog_content {
  height: 150px;
  display: block !important;
  margin-bottom: 0 !important;
  overflow-x: auto;
  padding-right: 8px;
}
.offcanvas {
  background-color: #003;
}
/* .btn-close{
  color: #fff !important;
  filter: invert(1);
} */
.staking_term_btngrp {
  flex-wrap: wrap;
}
.staking_term_btngrp.staking_method_btngrp button {
  width: auto;
  height: auto;
  padding: 10px 30px;
}














/* Dark Theme Css */
[data-theme="dark"] .navbar-toggler {
  filter: unset;
}
[data-theme="dark"] .main_navbar .offcanvas {
  background-color: #003;
}
[data-theme="dark"] .main_navbar .offcanvas-header .btn-close {
  filter: invert(1);
}
[data-theme="dark"] .toggletheme_btn {
  color: #fff;
}
[data-theme="dark"] .navbar-sticky--moved-up {
  background: #003 !important;
}
[data-theme="dark"] body {
  background: #003;
  color: #fff;
}
[data-theme="dark"] .page_header::before {
  background: url("../src/assets/images/banner_bg_dark.png") no-repeat bottom
    right;
  background-size: cover;
}
[data-theme="dark"] .main_navbar {
  border: 1px solid transparent;
  background: transparent;
}
[data-theme="dark"] .page_header_inner {
  /* border: 1px solid rgba(67, 67, 67, 0.75);
  background: rgba(29, 29, 29, 0.50); */
}
[data-theme="dark"] .page_header h1 {
  color: #fff;
}
[data-theme="dark"] .page_header p,
[data-theme="dark"] .primary_warning_alert p {
  color: #fff;
}
[data-theme="dark"] .uploaded_files_row {
  background: #233981;
}
[data-theme="dark"] .footer {
  border: 1px solid transparent;
  background: #000021;
}
[data-theme="dark"] .staking_method_btngrp button {
  color: #fff;
}
[data-theme="dark"] body::after {
  background: url("../src/assets/images/footer_bg_dark.png") no-repeat;
  background-size: cover;
  height: 294px;
}
[data-theme="dark"] .footer_panel_top
 {
  border-bottom: 1px solid rgba(67, 67, 67, 0.75);
}
[data-theme="dark"] .dash_box_right_top{
  border-bottom: 1px solid rgb(159 143 143 / 65%);
}
[data-theme="dark"] .footer_panel_top h3,
[data-theme="dark"] .footer_panel_bottom p a {
  color: #00e599;
}

[data-theme="dark"] .footer_menu_links li a,

[data-theme="dark"] .image_dropdown_wrapper .dropdown-menu .dropdown-item,
[data-theme="dark"] .bal,
[data-theme="dark"] .main_navbar a.nav-link,
[data-theme="dark"] .nav_after_login li a.nav-link,
[data-theme="dark"] .wallet_table td,
[data-theme="dark"] .table_asset span,
[data-theme="dark"] .deposit_notes_list,
[data-theme="dark"] .assets_form label,
[data-theme="dark"]
  .primary_datatable
  .rdt_TableBody
  .rdt_TableRow
  .rdt_TableCell,
[data-theme="dark"] .primary_datatable .rdt_Pagination,
[data-theme="dark"] .nav_after_login li a.dropdown-item,
[data-theme="dark"] .user_info_content h5 span,
[data-theme="dark"] .accordion-item,
[data-theme="dark"] .withdrawal_type {
  color: #ffffff !important;
}
[data-theme="dark"] .main_para{
  color:#778AD3;
}
[data-theme="dark"] .footer_panel_bottom p,
[data-theme="dark"] p.footer_abt_content {
  color: #fff;
}
[data-theme="dark"] .footer_quick_links li a {
  color: #fff;
}
[data-theme="dark"] .staking_result_div p {
  color: #fff !important;
}
[data-theme="dark"] .staking_calc label {
  color: #fff !important;
}
/* [data-theme="dark"] .staking_method_btngrp button{
  background-color: #003;
  color: #fff;
  border:none; 
} */
[data-theme="dark"] .orderbook_list .text {
  color: #fff;
}

button.trade_buy.trade_btn {
  background: #00E599;
  border: 1px solid #00E599;
  color: #fff;
}
button.trade_sell.trade_btn {
 background: #d53d3d;
  border: 1px solid #d53d3d;
  color: #fff;
}
[data-theme="dark"] .staking_method_btngrp1 button{
  background-color: #003 !important;
  color: white;
  border: 1px solid #003;
}
[data-theme="dark"] .staking_method_btngrp1 button.active{
  background: #00E599 !important;
  border: 1px solid #00E599;
  color: #fff;
}
/* [data-theme="dark"] .staking_method_btngrp button.active {
  background: #00e599;} */
[data-theme="dark"] .nav_after_login .dropdown svg path,
[data-theme="dark"] .nav_after_login li a.nav-link svg path {
  fill: #c7c7c7;
}



[data-theme="dark"] .primary_warning_alert h3
 {
  color: #d1cbcb;
 }
 [data-theme="dark"] .dash_box_right h4{
  color: #fff;
 }
[data-theme="dark"] .inner_title{
  color: #fff;
}
[data-theme="dark"] .wallet_table th{
  color: #fff;
}
[data-theme="dark"] .staking_result_div h3{
  color: #fff;
}
[data-theme="dark"] .staking_result_div span{
  color: #fff;
}
[data-theme="dark"] .faq_accordian .accordion-item button {
  color: #fff;
}
[data-theme="dark"] .faq_accordian .accordion-item{
  border-bottom: 1px solid #3E56A4;
}
.faq_accordian .accordion-button::after{
  /* filter: invert(1) !important; */
  /* filter: brightness(0) invert(1) grayscale(1) !important; */
}
[data-theme="dark"] .main_title {
  color: #fff;
}
[data-theme="dark"] .main_para b,
[data-theme="dark"] .countdown_single p {
  color: #2d2d2d;
}
[data-theme="dark"] .primary_btn_with_bg {
  border-color: #00E599 !important;
}
[data-theme="dark"] .countdown_single::before {
  content: "";
  background: url(./assets/images/countdownicon1.png);
  width: 155px;
  height: 175px;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
}

[data-theme="dark"] .countdown_single:nth-of-type(2)::before {
  content: "";
  background: url(./assets/images/countdownicon2.png);
  width: 162px;
  height: 175px;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
}
[data-theme="dark"] .countdown_single:nth-of-type(3)::before {
  content: "";
  background: url(./assets/images/countdownicon3.png);
  width: 170px;
  height: 175px;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
}
[data-theme="dark"] .countdown_single {
  border: 1px solid transparent;
  background: linear-gradient(143deg, rgb(63, 87, 165) -1.592%,rgb(0, 16, 87) 126.934%);
}
[data-theme="dark"] .countdown_single:hover {
  border: 1px solid transparent;
  background:linear-gradient(335deg, rgb(63, 87, 165) -1.592%, rgb(0, 16, 87) 126.934%);
}
[data-theme="dark"] .ticker-sec {
  background: linear-gradient(to top ,#3F57A5 0%,#001057 99%);
}
[data-theme="dark"] .countdown_single h3{
  color: #00E599;
}
[data-theme="dark"] .countdown_single p{
  color: #fff;
}
[data-theme="dark"] .countdown_single:hover h3{
  color: #fff;

}
[data-theme="dark"] .countdown_single:hover p{
  color: #eddddd;
  
}
/* [data-theme="dark"] .countdown_single:nth-child(2) {
  background: rgb(10 3 31 / 50%);
}
[data-theme="dark"] .countdown_single:nth-child(3) {
  background: rgb(0 23 28 / 50%);
} */
[data-theme="dark"] .countdown_single:nth-child(1) svg path:nth-child(1) {
  stroke: #fff;
}
[data-theme="dark"] .countdown_single:nth-child(2) svg path:nth-child(3),
[data-theme="dark"] .countdown_single:nth-child(2) svg path:nth-child(4) {
  stroke: #fff;
}
[data-theme="dark"] .countdown_single:nth-child(3) svg path:nth-child(2),
[data-theme="dark"] .countdown_single:nth-child(3) svg path:nth-child(3) {
  stroke: #fff;
}
[data-theme="dark"] .staking_works:nth-child(1) svg path:nth-child(5),
[data-theme="dark"] .staking_works:nth-child(1) svg path:nth-child(6) {
  stroke: #fff;
}
[data-theme="dark"] .staking_works:nth-child(2) svg path:nth-child(1),
[data-theme="dark"] .staking_works:nth-child(2) svg path:nth-child(2) {
  stroke: #fff;
}
[data-theme="dark"] .staking_works:nth-child(3) svg path:nth-child(5) {
  fill: #fff;
  stroke: #fff;
}
[data-theme="dark"] .staking_works h2 {
  color: #00E599;
}
[data-theme="dark"] .staking_calc {
  border: 1px solid rgba(67, 67, 67, 0.2);
  background: linear-gradient(to top ,#3F57A5 0%,#001057 99%);
  /* box-shadow: 0px 15px 25px 0px rgb(12 12 12 / 50%); */
  box-shadow:none;
}
[data-theme="dark"] .form-text {
  color: #b7b7b7;
}
[data-theme="dark"] .staking_result_div {
  border: 1px solid transparent;
  background: hwb(0deg 100% 0% / 15%);
}
[data-theme="dark"] .staking_calc input {
  background: #233981 !important;
  border: 1px solid #4D6CCF !important;
  color: #fff !important;
  /* padding: 45px; */
}
[data-theme="dark"] .image_dropdown {
  background: rgb(0 0 0 / 15%) url("../src/assets/images/select_dropdown_dark.png")
    no-repeat center right 15px !important;
  border: 1px solid #4D6CCF !important;
  color: #fff !important;
  background-color: #233981 !important;
  /* padding: 20px; */
}
[data-theme="dark"] .image_dropdown_wrapper .dropdown-menu {
  background:#4D6CCF!important
}
[data-theme="dark"] .innerpages_wrapper::before {
  background: url("../src/assets/images/banner_bg_dark.png") no-repeat;
  background-size: cover;
}
[data-theme="dark"] .auth_wrapper .dash_box,
[data-theme="dark"] .dash_wrapper {
  border: 1px solid rgba(67, 67, 67, 0.75);
  background: rgb(229 229 229 / 5%);
}
[data-theme="dark"] .dash_box {
  border: 1px solid rgb(67 67 67);
    /* background: rgb(205 205 205 / 17%); */
    /* background: linear-gradient(143deg, rgb(63, 87, 165) -1.592%,rgb(0, 16, 87) 126.934%); */
    background: linear-gradient(180.00deg, rgb(63, 87, 165) -1.592%,rgb(0, 16, 87) 126.934%);
}

[data-theme="dark"] .dash_wrapper1{
  background: linear-gradient(180.00deg, rgb(63, 87, 165) -1.592%,rgb(0, 16, 87) 126.934%);
}
 /* {
  background: rgb(0 0 0 / 15%) !important;
  border: 1px solid #1e1e1e !important;
  color: #fff !important;
} */
[data-theme="dark"] .auth_form input.formcontrol1,
[data-theme="dark"] .auth_form input.form-control{
  background: white !important;
  color: #000000 !important;
}
[data-theme="dark"] .auth_form span.withdrawal_type {  
  color: #00E599 !important;
}
[data-theme="dark"] .react-tel-input.formcontrol1 input{
  background: white !important;
  color: #000000 !important;
}
[data-theme="dark"] .react-tel-input .country-list .country .dial-code {
  color: #d4d4d4 !important;
}
[data-theme="dark"] .primary_asset_inp{
  border: 1px solid #4D6CCF !important;
  background: #233981 !important;
  color: #fff !important;
}
[data-theme="dark"] .chat_reply .primary_asset_inp{
  background-color: transparent !important;
}
[data-theme="dark"] .react-tel-input .country-list .search {
  background-color: #000033 !important;
}
[data-theme="dark"] .react-tel-input .country-list .search-box {
  background: rgb(0 0 0 / 15%) !important;
  border: 1px solid #e6e6e6 !important;
  color: #fff !important;
}
[data-theme="dark"] .react-tel-input .country-list .country.highlight,
[data-theme="dark"] .react-tel-input .country-list .country:hover {
  background-color: rgb(0 229 153 / 20%) !important;
}
[data-theme="dark"] .main_navbar a.nav-link.active,
[data-theme="dark"] .main_navbar a.nav-link:hover {
  color: #00E599 !important;
}
[data-theme="dark"] .wallet_table tbody tr:nth-child(odd) {
  background: rgb(230 255 248 / 4%);
}
[data-theme="dark"] .react-tel-input .country-list {
  background-color: #000033 !important;
}
[data-theme="dark"] .search_grp input {
  background: rgb(0 0 0 / 15%) !important;
  border: 1px solid #4D6CCF !important;
  color: #fff !important;
}
[data-theme="dark"] .search_grp input::placeholder,
[data-theme="dark"] .primary_asset_inp::placeholder,
[data-theme="dark"] .staking_calc input::placeholder
{
  color: #fff;
}
[data-theme="dark"] .react-tel-input .form-control::placeholder {
  color: white !important;
}
[data-theme="dark"] .search_icon,
[data-theme="dark"] .asset_copy_icon {
  filter: invert(1);
}
[data-theme="dark"] .primary_warning_alert {
  background: #0f11115b;
  border-color: #0f12112e;
}

[data-theme="dark"] .modal-content {
  background: linear-gradient(180deg, rgb(63, 87, 165) -1.592%,rgb(0, 16, 87) 126.934%);

}
[data-theme="dark"] .modal-header {
  border-color: #4D6CCF;
}
[data-theme="dark"] .modal-header .btn-close {
  filter: invert(1);
}
[data-theme="dark"] .primary_datatable .rdt_Table,
[data-theme="dark"] .primary_datatable .rdt_Table > div,
[data-theme="dark"] .user_info_content h3,
[data-theme="dark"] .primary_modal .modal-header h1 {
  color: #fff;
}
[data-theme="dark"] .staking_calc.trade_buy {
  box-shadow: none;
  background: transparent;
  border: none;
}
[data-theme="dark"] .staking_calc .input-group .primary_asset_inp {
  background: #233981 !important;
  
}
[data-theme="dark"] .input-group1 input{
  border: transparent !important;
  border-right: 1px solid #4D6CCF !important;
  border-left: 1px solid #4D6CCF  !important;
 
}
[data-theme="dark"] .input-group1{
  border: 1px solid #4D6CCF ;
  border-radius: 20px;
  background: transparent;
}
[data-theme="dark"] .primary_asset_select {
  border-color: #4D6CCF !important;
  background: rgb(0 0 0 / 15%)
    url("../src/assets/images/select_dropdown_dark.png") no-repeat;
  background-position: right 0.75rem center;
  color: #fff;
}
[data-theme="dark"] .primary_asset_select option {
  background-color: #003 !important;

}
[data-theme="dark"] .asset_dashbox_flex_line::after {
  background: #ffffff;
}
[data-theme="dark"] .primary_datatable .rdt_TableHeadRow .rdt_TableCol {
  color: #fff;
}
[data-theme="dark"]
  .primary_datatable
  .rdt_TableBody
  .rdt_TableRow:nth-child(odd) {
  background: rgb(0 0 0 / 30%);
}
[data-theme="dark"] .primary_datatable .rdt_Pagination button {
  fill: #c7c7c7 !important;
}
[data-theme="dark"] .primary_datatable .rdt_Pagination button:disabled {
  fill: #818181 !important;
}
[data-theme="dark"] .nav_after_login ul.dropdown-menu {
  border: 1px solid rgb(54 54 54);
  background: rgb(15 17 16);
}
[data-theme="dark"] .nav_after_login li a.dropdown-item:focus,
[data-theme="dark"] .nav_after_login li a.dropdown-item:hover {
  color: #fff !important;
}
[data-theme="dark"] .ticket_msg_card {
  background: linear-gradient(143deg, rgb(63, 87, 165) -1.592%,rgb(0, 16, 87) 126.934%) ;
  box-shadow: 0px 0px 15px 0px rgb(23 23 23 / 35%);
}
[data-theme="dark"] .ticket_msg_card_header p {
  color: #afafaf;
}
[data-theme="dark"] .user_info img {
  box-shadow: 0 15px 25px 0 #1b1b1b;
}
[data-theme="dark"] .ticker_content h3 {
  color: #000033;
}
[data-theme="dark"] .ticker_content h4 {
  color: #989898;
}
[data-theme="dark"] .crypto_box p {
  color: #ffffff;
}
[data-theme="dark"] .crypto_box {
  background-color:#003;
  border: 1px solid #373b3c;
}
[data-theme="dark"] .coins_box > div {
  border: 1px solid rgba(67, 67, 67, 0.3);
  background: rgba(29, 29, 29, 0.3);
}

[data-theme="dark"] .coins_box .accordion-item {
  margin-bottom: 12px !important;
  border-radius: 10px !important;
  background: #4D6CCF;
  border: 1px solid #4D6CCF;
}
[data-theme="dark"] .coins_box .accordion-item .accordion-button {
  border-radius: 10px !important;
  background: #4D6CCF;
  color: #ffffff;
  box-shadow: none !important;
}
[data-theme="dark"] .menu-list div:hover{
  background: #7091fb;
}
[data-theme="dark"] .orderbook_tbheader .content{
  color: #fff;
}
[data-theme="dark"] .orderbook_list_container .orderbook_tbody_row_bar_sell{
  background: rgb(254 83 91 / 48%);
  z-index: -1;
}
[data-theme="dark"] .orderbook_list_container .orderbook_tbody_row_bar_buy{
  background: rgb(56 173 123 / 54%);
  z-index: -1;
}
[data-theme="dark"] .orderbook_ticker{
  background: #003;
}
[data-theme="dark"] .accordion-button::after {
  filter: contrast(0) brightness(0) invert(1) !important;
}
[data-theme="dark"] .custom_pagination .active > .page-link,
[data-theme="dark"] .custom_pagination .page-link.active {
  background-color: #00E599;
  border-color: #00E599;
  color: #fff;
}
[data-theme="dark"] .custom_pagination .page-link {
  color: #c7c7c7;
  box-shadow: none !important;
  border: 1px solid rgba(67, 67, 67, 0.3);
  background: rgba(29, 29, 29, 0.3);
}
[data-theme="dark"] .Staking_operates_btn .btn {
  border: 1px solid #39509B;
  color: #3F57AB;
}
[data-theme="dark"] .Staking_operates_content{
  border: 1px solid #3E56A4;
}
[data-theme="dark"] .Staking_operates_content p{
  color: #778AD3;
}
[data-theme="dark"] .Staking_operates_content:hover p{
 color: #fff;
}
[data-theme="dark"] .Staking_operates h2{
  color: #fff;
}
[data-theme="dark"] .rewards-sec h2{
  color: #fff;
}
[data-theme="dark"] .rewards_content{
  border: 1px solid #3E56A4;
}
[data-theme="dark"] .rewards_content h6{
  color: #fff;
}
[data-theme="dark"] .rewards_content p{
  color: #778AD3;
}
[data-theme="dark"] .rewards_content:hover{
  box-shadow: 3px 3px 25px 5px rgba(0, 229, 153, 0.25);
  background: #00e599;
}
[data-theme="dark"] .rewards_content:hover img{
  filter: contrast(0) brightness(0) invert(1)
}
[data-theme="dark"] .rewards_content:hover p{
  color: #2D2D2D;
}
[data-theme="dark"] .image_dropdown_wrapper .dropdown-menu .dropdown-item:hover {
  background: rgb(101 146 229 / 48%);
  color: #fff;
}
[data-theme="dark"] .active1 {
  background-color: #00e599;
  box-shadow: 3px 3px 25px 5px rgba(0, 229, 153, 0.25);
}
[data-theme="dark"] .Staking_operates_content.active1 p{
  color: #fff;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
  background: transparent;
  border: transparent;
}
[data-theme="dark"] .formcontrol2{
  background-color: #233981 !important;
  border: 1px solid #4D6CCF !important;
}
[data-theme="dark"] textarea.form-control.primary_asset_inp{
  background: transparent !important;
}
[data-theme="dark"] .footer_quick_links li a:hover{
  color: #00b881;
}
[data-theme="dark"] .phone-number .form-control{
  background: #233981 !important;
  border: 1px solid #4D6CCF !important;
  color: #fff !important;
}
[data-theme="dark"] .react-tel-input .selected-flag .arrow {
  border-top: 4px solid #fff;
}
[data-theme="dark"] .react-tel-input .selected-flag .arrow.up {
  border-bottom: 4px solid #fff;
}

[data-theme="dark"] .snip1517 .plan {
  background: linear-gradient(180.00deg, rgb(63, 87, 165) -1.592%, rgb(0, 16, 87) 126.934%);
  border: 1px solid rgb(67 67 67);
 
}

[data-theme="dark"] .snip1517 {
  color: #fff;
 
}
[data-theme="dark"] .snip1517 .plan-title{
  color: #fff;
 
}
[data-theme="dark"] .snip1517 .plan-price  {
  color: #00E599;
  
}

[data-theme="dark"] .snip1517 .plan-features li {
  color: #fff;

}


 

/* Media Queries */
@media screen and (max-width: 1399px) {
  .countdown_single h3{
    font-size: 44px;
  }
  .page_header h1 {
    max-width: 75%;
  }
  .page_header p {
    max-width: 41%;
  }
  .rewards-sec h2{
    max-width: 83%;
  }
  .copy_trade_row .slick-prev:before {
    left: 12px;
    position: relative;
  }
  .dash_wrapper {
    border-radius: 30px;
    padding: 20px;
  }
  .dash_box.dash_box_right {
    padding: 36px 20px;
  }
  .dash_box_right_large_single h3 {
    font-size: 18px;
  }
  .primary_btn.primary_btn_transform {
    font-size: 14px;
    padding: 8px 12px;
  }
  .dash_box_right_top h4 {
    font-size: 16px;
  }
  .dash_box_right_bottom h2 {
    font-size: 28px;
  }
  /* .countdown_section {
    margin-top: -120px;
  } */
  .level_head > div:nth-child(1),
  .level_body_row > div:nth-child(1) {
    width: 32%;
  }
}
@media screen and (max-width: 1199px) {
  .page_header h1 {
    max-width: 91%;
  }
  .page_header p {
    max-width: 49%;
  }
  .rewards_content {
    padding: 30px;
  }
  .Staking_operates h2 {
    font-weight: 500;
    font-size: 32px;
  }
  .Staking_operates_content {
    padding: 22px;
  }
  .staking_result_div p {
    max-width: 19%;
  }
  [data-theme="dark"] .staking_calc input{
    padding: 30px;
  }
  .image_dropdown_wrapper.dropdown{
    padding: 0;
  }
  .staking_method_btngrp button {
    height: 60px;
  }
  .main_title {
    max-width: 58%;
  }
  .ticker_settings .slick-prev:before, .ticker_settings .slick-next:before{
    padding: 15px;
  }
  .Staking_operates_btn .btn {
    padding: 10px 20px;
  }
  .dash_box.dash_box_left {
    height: 100%;
  }
  .dashbox_left_inner_row [class*="col-"] {
    margin-bottom: 24px;
  }
  .page_header::after {
    display: none;
  }
  .countdown_section {
    margin-top: 0;
    padding-top: 70px;
  }
  .countdown_panel {
    flex-wrap: wrap;
    justify-content: center;
  }
  .features_panel p.main_para {
    max-width: 100%;
  }
  ul.navbar_left {
    margin-left: 20px;
    display: none;
  }
  .main_navbar {
    backdrop-filter: unset;
    background:#003;
  }
  /* .navbar-toggler {
    filter: invert(1);
    box-shadow: none !important;
  } */
  .navbar-toggler:focus{
    box-shadow: none;
  }
  .notify_active {
    right: -28px;
    top: 6px;
    left: unset;
  }
  .bal {
    color: #fff !important;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
  .support_ticket_accordian .accordion-header .accordion-button {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .support_ticket_accordian .accordion-button::after {
    top: 30px;
  }
  .tradingview-widget-container {
    height: 500px;
  }
}
@media screen and (max-width: 991px) {
  .page_header h1 {
    max-width: 100%;
    font-size: 32px;
}
.rewards-sec h2 {
  max-width: 100%;
  font-size: 30px;
}
.rewards_content_head {
  flex-direction: column;
  text-align: center;
}
.countdown_single h3 {
  font-size: 35px;
}
.page_header p {
  max-width: 73%;
}
.Staking_operates_btn {
  flex-direction: row;
  margin-bottom: 30px;
}
.Staking_operates_btn .btn{
  font-size: 17px;
}
.Staking_operates_content{
  margin-left: 0px;
}
.Staking_operates h2{
  text-align: center;
}
.main_title{
  max-width: 100%;
  font-size: 32px;
}
.section{
  padding-top: 40px;
}
.footer_quick_links{
  justify-content: center;
}
.footer_panel_top{
text-align: center;
}
p.footer_abt_content{
  max-width: 100%;
  margin: auto;
  padding-bottom: 30px;
}
.staking_result_div p {
  max-width: 30%;
}
.staking_result_div h3{
  font-size: 30px;
}
  p.footer_abt_content {
    max-width: 90%;
  }
  .dash_box_right_large_single {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .dash_box_right_large_single h5 {
    margin-bottom: 10px;
  }
  .dash_box_right_large_single::after {
    height: 100%;
  }
  .navbar_left {
    display: none;
  }
  .auth_btn_grp {
    flex-direction: column;
  }
  .auth_btn_grp .primary_btn {
    display: block;
    text-align: center;
  }
  .page_header_inner {
    text-align: center;
  }
  .footer {
    padding: 30px 0 80px;
  }
  .toolbar_bottom {
    display: block;
    position: fixed;
    bottom: 0;
    background: #00E599;
    width: 100%;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.12);
    transition: transform 0.25s;
    z-index: 999;
  }
  .toolbar_bottom ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 15px;
    height: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .toolbar_bottom ul li svg {
    fill: #fff;
  }
  .toolbar_bottom ul li {
    text-align: center;
  }
  .toolbar_bottom ul li span {
    display: block;
    color: #fff;
    font-size: 14px;
  }
  .toolbar_bottom .navbar-toggler {
    filter: unset;
  }
  .toolbar_bottom ul li a,
  .toolbar_bottom ul li button {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    padding: 12px;
  }
  .toolbar_bottom ul li a.active {
    background: #075e3f;
  }
  .level_head > div:nth-child(1),
  .level_body_row > div:nth-child(1) {
    width: 40%;
    margin-right: 30px;
  }
  .asset_dashbox_flex {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    gap: 30px;
  }
  .asset_dashbox_flex_left,
  .asset_dashbox_flex_right {
    width: 100%;
  }
  .asset_dashbox_flex_line::after {
    display: none;
  }
  .staking_calc [class*="col-"] {
    margin-bottom: 15px;
  }
  .auth_wrapper .dash_box {
    padding: 30px;
  }
  .dash_wrapper.dash_wrapper_flex {
    flex-direction: column;
  }
  .dash_wrapper_flex_left,
  .dash_wrapper_flex_right {
    width: 100%;
  }
  .dash_wrapper_flex::after {
    display: none;
  }
  .staking_top_flex {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .dash_box.trade_top_info {
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 50px;
    row-gap: 5px;
  }
  .coins_box {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .countdown_section {
    padding-top: 100px;
}
.rewards-sec h2{
  font-size: 23px;
}
  .dash_box_right_large {
    flex-direction: column;
    align-items: flex-start;
  }
  .dash_box_right_large_single {
    width: 100%;
    flex-direction: row;
  }
  .dash_box_right_large_single::after {
    width: 100%;
    height: 1px;
    right: unset;
    top: unset;
    bottom: -15px;
  }
  .staking_result_div p{
    max-width: 100%;
    padding-bottom: 20px
  }
  .progress_note {
    flex-direction: column;
    gap: 10px;
  }
  .custom_progress .progress,
  .progress_note {
    width: 92%;
  }
  .progress_note label {
    text-align: center;
  }
  .footer_panel_bottom {
    flex-direction: column;
    text-align: center;
  }
  .footer_panel_bottom p {
    line-height: 24px;
  }
  .page_header h1 {
    font-size: 21px;
    line-height: 32px;
}
.page_header p {
  max-width: 100%;
}
.page_header_inner img{
  height: 360px;
}
  .countdown_single h3 {
    font-size: 32px;
    line-height: 42px;
  }
  .features_panel {
    padding: 30px 50px;
  }
  .main_title {
    font-size: 36px;
    line-height: 42px;
  }
  .features_panel h3 {
    margin: 10px 0;
  }
  .auth_wrapper {
    padding: 50px 0;
  }
  .faq_accordian .accordion-item button {
    font-size: 16px;
  }
  .level_head > div:nth-child(1),
  .level_body_row > div:nth-child(1) {
    width: 180px;
    margin-right: 10px;
  }
  .level_head > div:nth-child(3),
  .level_body_row > div:nth-child(3),
  .level_head > div:nth-child(2),
  .level_body_row > div:nth-child(2) {
    width: 150px;
    margin-right: 10px;
  }
  .deposit_asset_details > div {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .deposit_asset_details > div label {
    width: 100%;
    line-height: 24px;
  }
  .notification_panel_row {
    flex-direction: column;
  }
  .notification_panel_row p.notify_date {
    margin-left: 15px;
  }
  .level_ribbon,
  .level_ribbon.active {
    background-size: contain;
  }
  .notify_active {
    right: -25px;
    left: unset;
  }
  .staking_method_btngrp {
    flex-wrap: wrap;
  }
  .staking_method_btngrp button {
    width: 48%;
  }
  .staking_result_div p {
    text-align: center;
  }
  .staking_result_div {
    flex-direction: column;
    padding: 30px;
    text-align: center;
  }
  .page_header_inner {
    padding: 30px;
  }
  .support_ticket_accordian .accordion-header .accordion-button > div {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .ticket_msg_card_user,
  .ticket_msg_card_admin {
    width: 100%;
  }
  .ticket_msg_card {
    padding: 15px;
  }
  .search_grp,
  .search_grp input {
    width: 100%;
  }
  .wallet_page_right_flex {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 575px) {
 .countdown_section {
    padding-top: 213px;
}
[data-theme="dark"] .countdown_section {
  padding-top: 91px;
}
  .Staking_operates_btn{
    display: none;
  }
  .faq-sec{
    margin-top: -20px;
  }
  .Staking_operates h2 {
    /* text-align: center; */
    padding-bottom: 20px;
}
  .page_header_inner img {
    height: 267px;
}
.Staking_operates h2{
font-size: 26px;
padding-top: 50px;
}
.Staking_operates_content{
  text-align: center;
flex-direction: column;
}

.Staking_operates_content.active1 img {
  filter: unset;
}
.Staking_operates_content.active1 p {
  color: #3E3E3E;
}
[data-theme="dark"] .active1 {
  background-color: transparent;
  box-shadow: none;
}
[data-theme="dark"] .Staking_operates_content.active1 p {
  color: #778AD3 ;
}
.active1{
  background: transparent;
}

.main_title {
  font-size: 26px;
  line-height: 38px;
}
.rewards-sec h2{
font-size: 20px;
line-height: 31px;
}
/* .countdown_section {
  /* padding-top: 198px; 
  margin-top: 152px;
} */
   .footer_panel_bottom p {
padding-bottom: 20px;
  }
  .uploaded_files_row > div span {
    max-width: 60%;
    overflow: hidden;
  }
  .uploaded_files_row {
    align-items: flex-start;
  }
  .inner_subtitle_wrap_flex {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  .dash_box_right_large_single h3 {
    font-size: 16px;
  }
  .dash_box_right h5 {
    font-size: 14px;
  }
  .level_body_row > div,
  .level_head > div {
    font-size: 14px;
  }
  .custom_progress .progress,
  .progress_note {
    width: 85%;
  }
  .asset_dashbox_flex {
    padding: 15px;
  }
  .assets_form label,
  label.max_label {
    font-size: 14px;
  }
  .deposit_asset_details > div label {
    font-size: 15px;
  }
  .asset_transfer_flex {
    flex-wrap: wrap;
    justify-content: center;
  }
  .asset_transfer_flex img {
    transform: rotate(90deg);
  }
  .user_info {
    flex-direction: column;
    align-items: flex-start;
  }
  .staking_method_btngrp button {
    width: 100%;
    padding: 12px 15px;
  }
  .page_header h1 {
    font-size: 20px;
    line-height: 27px;
}
.page_header p {
  font-size: 12px;
}
  .auth_wrapper .dash_box {
    padding: 30px 15px;
  }
  .ticker_panel {
    width: 85%;
    margin: 0 auto;
    gap: 5px;
  }
  .ticker_settings .slick-prev {
    left: 0;
    z-index: 1;
  }
  .ticker_settings .slick-next {
    right: 0;
  }
  .ticker_panel img {
    width: 40px;
    height: 40px;
  }
  .ticker_content h3 {
    font-size: 20px;
  }
  .ticker_content h4 {
    font-size: 16px;
    gap: 10px;
  }
}
.verticalScroll > div:first-child {
  padding-right: 20px !important;
}
.verticalScroll > div:last-child {
  margin-right: 0px;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.4) !important;
  width: 5px !important;
}
.verticalScroll > div:last-child > div {
  background: #00E599 !important;
}

.orderbook_wrap {
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
  grid-area: orderbook / orderbook / orderbook / orderbook;
  position: relative;
  overflow: hidden;
  /* background: var(--dark-01); */
  /* padding-bottom: 8px; */
}

.orderbook_wrap_inner {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  /* height: 100%; */
  /* background: var(--dark-01); */
  border-radius: 0 0 10px 10px;
}

.orderbook_header {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  background: var(--spot_headers);
  border-bottom: 1px solid var(--grey-04);
}

.orderbook_header .orderbook_header_tips {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.orderbook_header_tips button {
  border: none;
  background: transparent;
  padding: 0;
}

.orderbook_header_tips button + button {
  margin-left: 12px;
}

.orderbook_tickSize {
  position: initial;
  right: 0px;
  flex: 1.2 1 0%;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 4px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.orderbook_tickSize select {
  border: none;
  /* background: transparent url("/assets/images/caret_arrow_icon.png") no-repeat right 0.75rem center; */
  color: red;
  /* font-size: var(--font-6); */
  box-shadow: none !important;
}

.orderbook_tbheader {
  display: flex;
  flex-direction: column;
  margin: 6px 13px 6px 0px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 0;
}

.orderlist_container {
  display: flex;
  flex: 1 1 0%;
  padding: 8px 0;
  flex-direction: column;
}

.orderbook_list {
  position: relative;
  display: flex;
  flex-direction: column;
  /* flex: 1 1 0%; */
  padding: 10px 0;
}

.orderbook_ticker {
  display: flex;
  padding: 1px 12px;
  height: 33px;
  -webkit-box-align: center;
  align-items: center;
  background: #003;
  justify-content: space-between;
  font-size: 14px;
  color: white;
}

.orderbook_tbheader .content {
  display: flex;
  height: 20px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  font-size: 12px;
  color: black;
}

.orderbook_tbody_row_bar_sell {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.orderbook_tbody_row_bar_buy {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.orderbook_list_container .orderbook_tbody_row_bar_sell {
  background: rgb(254 83 91 / 68%);
  z-index: -1;
}

.orderbook_list_container .orderbook_tbody_row_bar_buy {
  background: rgb(56 173 123 / 54%);
  z-index: -1;
}

.orderbook_tbheader .item {
  display: flex;
  flex: 1 1 0%;
  font-size: 14px;
  font-weight: 500;
}

.orderbook_progress .row_content {
  padding-left: 10px !important;
  padding-right: 8px !important;
}

.orderbook_progress {
  height: 22px;
  width: 100%;
  box-sizing: border-box;
}

.orderbook_list .row_content {
  display: flex;
  box-sizing: border-box;
  z-index: 2;
  width: 98%;
  height: 100%;
  line-height: 22px;
  cursor: pointer;
}

.orderbook_list .text {
  font-size: 12px;
  margin-left: 0px;
  flex: 1 1 0%;
  text-align: right;
  color: black;
  cursor: pointer;
  font-weight: 300;
}

.orderbook_list .progress_container {
  height: 22px;
}

.orderlist_container.show_sell {
  padding-bottom: 0;
}

.orderlist_container.show_sell .orderbook_bid {
  display: none;
}

.orderbook_ask .orderbook_list_container {
  display: flex;
  flex-direction: column-reverse;
}

.orderlist_container.show_sell .orderbook_list_container {
  height: 767px;
}

.orderlist_container.show_buy {
  padding-bottom: 0;
}

.orderlist_container.show_buy .orderbook_ask {
  display: none;
}

.orderlist_container.show_buy .orderbook_list_container {
  height: 767px;
}

.progress_container {
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 1 1 0%;
  height: 20px;
  -webkit-box-align: center;
  align-items: center;
  animation: 0.3s ease-out 0s 1 normal none running none;
  overflow: hidden;
}

.orderbook_list .ask_bar {
  background-color: var(--red);
}

/* .orderbook_list .progress_bar {
  height: 22px;
}
.orderbook_progress .progress_bar {
  position: absolute;
  right: 0px;
  z-index: 1;
  height: 20px;
  opacity: 0.1;
  width: 100%;
  left: 100%;
} */
.orderbook_list .ask_light,
.orderbook_list .bid_light {
  font-size: 12px;
  flex: 1 1 0%;
  text-align: left;
}

.orderbook_list .ask_light {
  color: var(--red);
}

.orderbook_list .bid_light {
  color: var(--green);
}

.orderbook_ticker .yellow {
  color: var(--color-01);
}

.orderbook_ticker .status_sell {
  color: var(--red);
}

.orderbook_ticker .status_green,
.orderbook_ticker .status_buy {
  color: var(--green);
}

.orderbook_ticker .status_primary {
  color: #1e2329;
}

.orderbook_ticker .contractPrice {
  display: flex;
  margin-right: 4px;
  -webkit-box-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}

.orderbook_ticker .markPrice {
  flex: 1 1 0%;
  display: inline-block;
  font-size: 12px;
  text-align: left;
  color: #707a8a;
}

.orderbook_ticker .more {
  color: #707a8a;
  font-size: 12px;
  text-decoration: none;
}

.orderbook_ticker .arrow_icon {
  font-size: 16px;
}

.orderbook_list .bid_bar {
  background-color: #0ecb81;
}

.orderbook_list_container {
  position: relative;
  height: 367px;
  height: 167px;
  overflow: hidden;
  will-change: transform;
  direction: ltr;
}

.chart_wrapper {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  grid-area: chart / chart / chart / chart;
  position: relative;
}

.snip1517 .plan{
  background-color: #fcfdfd;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 10px;
  margin: 0 .5%;
  overflow: hidden;
  padding-top: 10px;
  position: relative;
  text-align: center;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.snip1517 {
  color: #212529;
  display: flex;
  flex-grow: 1;
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 16px;
  margin: 30px 10px;
  text-align: left;
  width: 100%;

}
.snip1517 .plan-title {
  color: #003 ;
  font-size: 1.6em;
  font-weight: 600;
  position: relative;
  font-family: Poppins, sans-serif;
}
.snip1517 .plan-price {
  color: #00E599;
  display: inline-block;
  font-size: 1.8em;
  font-weight: 400;
  margin: 10px 0;
}
.snip1517 .plan-features {
  font-size: .9em;
  list-style: none outside none;
  margin: 0;
  padding: 0 0 20px;
}
.snip1517 .plan-features li {
  color: #212529;
  padding: 8px 20px;
}
.snip1517 .plan-select {
 
  padding: 20px;
  text-align: center;
}
.snip1517 .plan-cost, .snip1517 .plan-title {
  margin: 0;
  padding: 0 20px;
}
.border_btn {
  padding: 6px 20px;
  font-size: 16px;
  text-transform: capitalize;
  color: #00e599;
  border: 1px solid #00e599;
  border-radius: 20px;
  background: transparent;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  font-family: "Gilroy";
  font-weight: 600;
}
.border_btn:hover {
  color: #fff;
  border: 1px solid #00e599;
  background: #00e599;
}
.chart-group-button .primary_btn:hover{
  box-shadow: none;
  color: #fff;
  border: 1px solid #00e599;
  background: #00e599;
}
.market_pair {
  display: flex;
  align-items: center;
  gap: 10px;
}
.market_pair img {
  width: 32px;
}
.market_pair p {
  margin-bottom: 0;
  color: #00e599;
}
.market_pair p span {
  color: #626262;
  font-size: 14px;
  margin-left: 2px;
}

[data-theme="dark"] .close_trade_info_offcanvas {
  background-color: #0b0e11;
}
[data-theme="dark"] .close_trade_info_offcanvas .offcanvas-header .btn-close {
  filter: invert(1);
}
[data-theme="dark"] .offcanvas-title {
  color: #fff;
}
[data-theme="dark"] .close_trade_info > div label {
  color: #cdcdcd;
}
[data-theme="dark"] .close_trade_info > div label:nth-child(2) {
  color: #fff;
}

.info_btn {
  padding: 4px 15px;
  font-size: 14px;
  text-transform: capitalize;
  color: #fff;
  border: 1px solid #00E599;
  border-radius: 20px;
  background: #00E599;
  transition: all 0.3s ease 0s;
  outline: none;
  font-family: "Gilroy";
  font-weight: 600;
}